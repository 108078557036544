import { List } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./navBar.css";

export default function Navbar({ className, id = 0 }) {
  const [showNavbar, setShowNavbar] = useState(false);
  const [categories, setCategory] = useState([]);
  const [t] = useTranslation();
  const [colorChange, setColorchange] = useState(false);
  // const refresh = () => window.location.reload(true);
  const navigate = useNavigate();
  const nav = () => {
    navigate("/all-products/10");
  };

  // eslint-disable-next-line no-unused-vars
  const changeNavbarColor = () => {
    if (window.scrollY >= 490) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const fetchData = async () => {
    const response = await fetch(
      "http://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCategory(data["categories"]);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <nav
      className="navbar-scroll"
      style={{ borderBottom: "2px solid #fbb033" }}
    >
      <div className="containers">
        <div className="logo">
          <a href="/" style={{ marginRight: "10px" }}>
            <img
              width="100px"
              height="20px"
              src="assets\images\castellologo.png"
              alt="logo"
            />
          </a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <List size={32} />
        </div>
        <div
          className={`nav-elements  ${showNavbar && "active"}`}

          // onClick={refresh}
        >
          <ul>
            <li onClick={() => setShowNavbar(false)}>
              <span className="flex items-center text-sm text-qblacktext font-600 cursor-pointer ">
                <Link
                  to="/"
                  className={
                    colorChange ? "navbar-links colorChange" : "navbar-links"
                  }
                >
                  <span className="link">{t("Home Page")}</span>
                </Link>
              </span>
            </li>
            <li onClick={() => setShowNavbar(false)}>
              <span className="flex items-center text-sm text-qblacktext font-600 cursor-pointer ">
                <Link
                  to="/all-products"
                  className={
                    colorChange ? "navbar-links colorChange" : "navbar-links"
                  }
                >
                  <span className="link"> المنتجات</span>
                </Link>
              </span>
            </li>
            <li onClick={() => setShowNavbar(false)}>
              <span className="flex items-center text-sm text-qblacktext font-600 cursor-pointer ">
                <Link
                  to="/about"
                  className={
                    colorChange ? "navbar-links colorChange" : "navbar-links"
                  }
                >
                  <span className="link"> {t("About")}</span>
                </Link>
              </span>
            </li>
            <li onClick={() => setShowNavbar(false)}>
              <span className="flex items-center text-sm text-qblacktext font-600 cursor-pointer ">
                <Link
                  to="/contact"
                  className={
                    colorChange ? "navbar-links colorChange" : "navbar-links"
                  }
                >
                  <span className="link">{t("Contact")}</span>
                </Link>
              </span>
            </li>
          </ul>
        </div>

        <div>
          <div className="res-logo mt-10">
            <a href="/">
              <img
                style={{ objectFit: "cover", width: "100px" }}
                // width="100px"
                // height="20px"
                src="assets\images\castellologo.png"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
