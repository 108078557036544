/* eslint-disable no-unused-vars */
import { Link, useNavigate } from "react-router-dom";
import "./category.css";

const Categories = ({ name = "", image = "", id = 0 }) => {
  // const navigate = useNavigate();

  return (
    // <div className=" w-full group cursor-pointer">
    <div className="category-container">
      <Link to={`/all-products/${id}`}>
        <div className="single-category">
          <div className="category-name">
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "50px",
                fontFamily: "light",
              }}
            >
              {name}
            </p>
          </div>
          <div className="category-image">
            <img
              src={image}
              style={{
                width: "100%",
                height: "100%",
                borderBottomLeftRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
              alt="categories"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Categories;
