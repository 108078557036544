/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import { data } from "autoprefixer";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ViewMoreTitle from "../../Helpers/ViewMoreTitle";
import "./product.css";

export default function SectionStyleOne({
  className,
  categoryTitle,
  url,
  sectionTitle,
  seeMoreUrl,
  brands = [],
  products = [],
  products1,
  categoryBackground,
  datas,
}) {
  SectionStyleOne.propTypes = {
    products1: PropTypes.instanceOf(Array).isRequired,
  };
  const filterBrands = brands.filter(
    (value, index, array) => array.indexOf(value) === index
  );
  const [productLength] = useState(products.length);
  return (
    <div>
      <div
        className="product-section-body"
        style={{ backgroundColor: "#e5f5fc", paddingBottom: "20px" }}
      >
        <div className="product-section">
          <ViewMoreTitle seeMoreUrl={seeMoreUrl}>
            <div
              className="products-section w-full "
              style={{ textAlign: "center" }}
            >
              <div
                style={{
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Light",
                  color: "black",
                  fontWeight: "bolder",
                  textDecoration: "underline",
                }}
              >
                <Link to="/all-products">المنتجات</Link>
              </div>
              <div
                className="mr-50 ml-90 mt-6"
                style={{
                  fontSize: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Light",
                  color: "black",
                }}
              >
                تفضل بتذوق السعادة الساحرة في كل
              </div>
              <div
                className="mb-10"
                style={{
                  fontSize: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Light",
                  color: "black",
                }}
              >
                لقمة من كاستيلو!
              </div>
              <Link to="/all-products">
                <div className="resp-pic">
                  <img
                    src="../../assets/images/rep-pic.jpeg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </Link>
              <div className="container">
                <div className="image-gallery">
                  <div className="column">
                    <Link to="/all-products">
                      <div className="image-item1">
                        <img
                          src="../../assets/images/hy.jpeg"
                          // src={
                          //   products.length === 0 ? "" : products[0]["image"]
                          // }
                          // alt=""
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <div className="column2">
                      {/* <Link to="/all-products/10"> */}
                      <div className="image-item3">
                        <img
                          src="../../assets/images/er.jpeg"

                          // src={
                          //   products.length === 0 ? "" : products[1]["image"]
                          // }
                          // alt=""
                        />
                      </div>
                      {/* </Link> */}
                      {/* <Link to="/all-products/10"> */}
                      <div className="image-item4">
                        <img
                          src="../../assets/images/t.jpeg"
                          // src={
                          //   products.length === 0 ? "" : products[2]["image"]
                          // }
                          // alt=""
                        />
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ViewMoreTitle>
          {/* <div className="mt-10">
            <button className="button-3" role="button">
              {" "}
              عرض المزيد!
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
