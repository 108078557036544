/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import About from "../About";
import AboutUs from "../About/About";
import ContactUs from "../Contact/Contact";
import Layout from "../Partials/Layout";
import Banner from "./Banner";
import "./loading.css";
import SectionStyleOne from "./Products/SectionStyleOne";
import AboutUsPage from "../About/about1";

export default function Home() {
  const [products, setProducts] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setProducts(data["products"]);
    setSliders(data["sliders"]);
    setCategories(data["categories"]);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  const brands = [];
  products.forEach((product) => {
    brands.push(product.name);
  });
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <Banner className="banner-wrapper" sliders={sliders} />

          <div>
            <SectionStyleOne
              products={products}
              brands={brands}
              seeMoreUrl="/all-products"
              // className="category-products"
            />
          </div>
          <div
            className="mt-5 mb-5"
            style={{
              fontSize: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "Light",
              color: "black",
              fontWeight: "bolder",
              textDecoration: "underline",
            }}
          >
            من نحن
          </div>
          <AboutUsPage />
          <ContactUs />
        </Layout>
      )}
    </>
  );
}
