import { useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { useNavigate, useParams } from "react-router-dom";
import DataIteration from "../../../Helpers/DataIteration";
import Layout from "../../../Partials/Layout";
import BannerSection from "../../BannerSection/BannerSection";
// eslint-disable-next-line import/extensions

import ProductCardStyleOne from "../ProductCardStyleOne";
import SectionStyleOne from "../SectionStyleOne";

export default function AllProductPage() {
  const params = useParams();
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchItem = async () => {
    const url = "https://castello.ps/fadi_shawer_backend/api/all_products";
    const response = await fetch(url);
    const item = await response.json();
    setProduct(item.products);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchItem();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <div className="res-bann">
            <BannerSection image="../../assets/images/resproduct.jpg" />
          </div>
          <div className="bann">
            <BannerSection image="../../assets/images/products.jpg" />
          </div>
          <div className="products-page-wrapper w-full">
            <div className="container-x mx-auto" style={{ width: "1100" }}>
              <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="flex-1">
                  <div className="products-sorting w-full md:h-[20px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[10px] mb-[10px]"></div>
                  <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5">
                    <DataIteration
                      datas={product}
                      startLength={0}
                      endLength={product.length}
                    >
                      {({ datas }) => (
                        <div data-aos="fade-up" key={datas.id}>
                          <ProductCardStyleOne datas={datas} />
                        </div>
                      )}
                    </DataIteration>
                  </div>

                  <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]"></div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
