/* eslint-disable no-unused-vars */
import { useTheme } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SocialMediaButtons from "./components/Home/SocialMediaButtons/SocialMediaButtons";
import HeaderOne from "./components/Partials/Headers/HeaderOne";
import i18n from "./i18n/i18n";
import Routers from "./Routers";

function App() {
  const location = useLocation();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  useEffect(() => {
    i18n.changeLanguage("ar");
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    if (location.pathname === "/home-two") {
      document.body.classList.add("home-two");
    } else if (location.pathname === "/") {
      document.body.classList.remove("home-two");
      document.body.classList.add("home-one");
    }
    document.body.classList.add("home-one");
    return () => {
      document.body.classList.remove("home-two");
      document.body.classList.add("home-one");
    };
  }, [location.pathname, theme.direction]);
  useEffect(() => {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const [name, _] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 2500:00:00 UTC; path=/;`;
    });
  }, []);
  return (
    <div>
      <SocialMediaButtons />
      <HeaderOne />
      <Routers />
    </div>
  );
}

export default App;
