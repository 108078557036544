import React, { useEffect, useState } from "react";
import "./about1.css";
import { useTransition } from "react";
import { Link } from "react-router-dom";

export default function AboutUsPage() {
  const [about, setAbout] = useState("");
  const [t] = useTransition();
  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setAbout(data["abouts"]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="aboutus-wrapper w-full">
      <div className="container-x mx-auto">
        <div className="w-full min-h-[665] lg:flex lg:space-x-12 items-center lg:pb-0">
          <div className="md:w-[570px] w-full md:h-[500px] h-auto rounded overflow-hidden my-5 lg:my-0 ml-10">
            <img
              src="assets/images/about.jpeg"
              alt="about"
              className="main-image"
            />
          </div>

          <div className="content flex-1">
            <h1 className="text-[18px] font-medium text-qblack mb-2.5">
              تعرف علينا أكثر!{" "}
            </h1>
            <p
              className="text-[15px] text-qgraytwo leading-7 mb-2.5"
              style={{ fontFamily: "Light", color: "black" }}
            >
              {about.body_ar}
            </p>

            <Link to="/about">
              <div className="mt-10 mr-40 ">
                <button className="button-51" role="button">
                  إقرأ المزيد!
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    //   <div className="aboutus-wrapper w-full">
    //   <div className="container-x mx-auto">
    //     <div className="w-full min-h-[665px] lg:flex lg:space-x-12 items-center pb-10 lg:pb-0">
    //       <div className="md:w-[570px] w-full md:h-[560px] h-auto rounded overflow-hidden my-5 lg:my-0">
    //         <img
    //           src="assets/images/pic.jpeg"
    //           alt="about"
    //           className="w-full h"
    //         />
    //       </div>
    //       <div className="content flex-1">
    //         <h1 className="text-[18px] font-medium text-qblack mb-2.5">
    //           من نحن
    //         </h1>
    //         <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
    //          {about.body_ar}
    //         </p>

    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div
    //   className="about-cont mx-auto mt-10"
    //   style={{ width: "1050px" }}
    // >

    //   <div className="main flex flex-row">
    //     <img
    //       className="main-image"
    //       src="assets/images/about.jpeg"
    //       alt="About us"
    //       style={{ width: "50%", borderRadius: "10px" }}
    //     />
    //     <div className="mt-10">
    //       <div className="main-text">
    //         {/* <h2
    //           className="text-center"
    //           style={{
    //             fontSize: "28px",
    //             textTransform: "uppercase",
    //             fontFamily: "Light",
    //             fontWeight: "bolder",
    //             color: "black",
    //           }}
    //         >
    //           من نحن
    //         </h2> */}
    //         <div
    //           className="mr-10 mt-1"
    //           style={{
    //             fontSize: "16px",
    //             fontFamily: "Light",
    //             fontWeight: "bolder",
    //             color: "black",
    //           }}
    //         >
    //           {about.body_ar}
    //         </div>
    //         <div className="mt-10 mr-40 ">
    //           <button className="button-51" role="button">
    //             إقرأ المزيد!
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
