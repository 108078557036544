import { EnvelopeSimple, MapPin, Phone } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Map } from "react-map-gl";
import InputCom from "../Helpers/InputCom";
import BannerSection from "../Home/BannerSection/BannerSection";
import Layout from "../Partials/Layout";
import ContactUs from "./Contact";
import "./contact.css";

export default function Contact() {
  const [social, setSocial] = useState("");
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setSocial(data["socials"]);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          {/* <BannerSection
            Text="اتصل بنا"
            image="assets/images/pic.jpeg"
          /> */}
          <div className="res-bann">
            <BannerSection
              Text=" اتصل بنا"
              image="assets/images/res-about-contact.jpg"
            />
          </div>
          <div className="bann">
            <BannerSection
              Text="اتصل بنا"
              image="assets/images/contactus.jpg"
            />
          </div>
          <ContactUs />
        </Layout>
      )}
    </>
  );
}
