/* eslint-disable no-unused-vars */
import "./BannerSection.css";

export default function BannerSection({ image = "", Text = "" }) {
  return (
    <>
      <div className="banner-section">
        <img src={image} alt="" style={{ width: "100%" , objectFit:'cover' }} />
        <span className="banner-section-text">{Text}</span>
      </div>
    </>
  );
}
