/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import React from "react";
import "./Banner.css";
import { useTranslation } from "react-i18next";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Banner({ className, sliders }) {
  const [t] = useTranslation();
  Banner.propTypes = {
    sliders: PropTypes.instanceOf(Array).isRequired,
  };
  return (
    <>
      <div
        className={`w-full ${className || ""}`}
        style={{ marginTop: "60px" }}
      >
        <div className="slide-container">
          <Fade>
            {sliders.map((i) => (
              <img src={i.image} alt="sliders" className="sliders" />
            ))}
          </Fade>
        </div>
        <div className="res-slide-container">
          <Fade>
            {sliders.map((i) => (
              <img src={i.image_mobie} alt="sliders" className="sliders" />
            ))}
          </Fade>
        </div>
        <div
          style={{
            height: "40px",
            width: "100%",
            // background: ,
            position: "absolute",
            top: "680.5px",
            zIndex: "1",
          }}
        ></div>
      </div>
      <div></div>
    </>
  );
}
