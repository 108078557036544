import { useState } from "react";
import Navbar from "./Navbar";
import "./navBar.css";


export default function HeaderOne({ className }) {
  
  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      {/* <Middlebar className="quomodo-shop-middle-bar lg:block hidden" /> className={`quomodo-shop-nav-bar lg:block${visible}`} */}
      <Navbar  />
    </header>
  );
}
