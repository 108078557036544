import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Facebook from "../../../Helpers/icons/Facebook";

export default function Footer() {
  const [social, setSocial] = useState("");
  const [t] = useTranslation();
  const fetchData = async () => {
    const response = await fetch(
      "http://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setSocial(data["socials"]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <footer className="footer-section-wrapper bg-white print:hidden">
      <div className="container-x block mx-auto pt-[20px] pb-[20px]">
        {/* <div className="lg:flex justify-between mb-[50px]">
          <div className="lg:w-[424px]  ml-0 w-full mb-10 lg:mb-0">
            <h1 className="text-[18] font-500 text-[#2F2F2F] mb-5">
              معلومات عنا
            </h1>
            <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
              {/* We know there are a lot of threa developers our but we pride into a firm in the industry. */}
            {/* </p>
          </div>
          <div className="flex-1 lg:flex">
            <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#2F2F2F]">المميزات</h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-4 ">
                  <li>
                    <a href="/about">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        حول
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/terms-condition">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize"></span>
                    </a>
                  </li>
                  <li>
                    <a href="/all-products">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        أفضل المنتجات
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 ">
              <div>
                <ul className="flex flex-col space-y-4 ">
                  معلومات الاتصال
                  <li>
                    <span className="text-[#9A9A9A] text-[15px]">
                      {social.address}
                    </span>
                  </li>
                  <li>
                    <span className="text-[#9A9A9A] text-[15px]">
                      {social.email}
                    </span>
                  </li>
                  <li>
                    <span className="text-[#9A9A9A] text-[15px]">
                      {social.phone1}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="bottom-bar border-t border-qgray-border lg:h-[82px] lg:flex justify-between items-center">
          <div className="flex lg:space-x-5 justify-between items-center mb-3">
            <div className="flex space-x-5 items-center">
              {/* <a href="#">
                                <Instagram className="fill-current text-qgray hover:text-qblack" />
                            </a> */}
              {/* <a
                href="https://www.facebook.com/perfectoadv"
                style={{ margin: "10px" }}
              >
                <Facebook className="fill-current text-qgray hover:text-qblack" />
              </a> 
              <a href="#">
                                <Youtube className="fill-current text-qgray hover:text-qblack" />
                            </a> 
            </div> */}
            <span className="sm:text-base text-[10px] text-qgray font-300">
              ©2023
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className="font-500 text-qblack mx-1"
              >
                Perfect
              </a>
              جميع الحقوق محفوظة
            </span>
          </div>
      
     
    </footer>
  );
}
