/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Layout from "../../Partials/Layout";
import "./product.css";
import ModalImage from "react-modal-image";

export default function ProductCardStyleOne({ datas }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/single-product2/${datas.id}`);
  }

  return (
    <>
      <div className="product-card">
        <div
          className="product-card-one w-full h-full bg-white relative group overflow-hidden"
          style={{
            borderRadius: "10px",
          }}
        >
          <div>
            <div className="product-card-img w-full h-[full]">
              <ModalImage
                small={datas.image}
                large={datas.image}
                alt=   {datas.name}

                style={{ height: "330px", width: "100%", objectFit: "cover" }}
              />

              {/* <img
                src={datas.image}
                alt="product"
                style={{ height: "330px", width: "100%", objectFit: "cover" }}
              /> */}
              <div
                className="product-card-details"
                style={{
                  backgroundColor: "#fcb033",
                  height: "6vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  className="title text-[15px] font-600 text-qwhite"
                  style={{ textAlign: "center", fontFamily: "Light" }}
                >
                  {datas.name}
                </p>
              </div>
            </div>
            {/* <div>
              <p
                className="title text-[40px] font-600 text-black mt-20 "
                style={{
                  textAlign: "center",
                  fontFamily: "Light",
                  fontWeight: "bolder",
                }}
              >
                {datas.name}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
