/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "../Home/BannerSection/BannerSection";
import Layout from "../Partials/Layout";
import "./about1.css";
import "video-react/dist/video-react.css";
import YouTube from "react-youtube";
import ModalVideo from "react-modal-video";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Fade, Slide } from "react-slideshow-image";

export default function About() {
  const [about, setAbout] = useState("");
  const [isOpen, setOpen] = useState(false);

  const [t] = useTranslation();

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      controls: 1,
    },
  };
  const opts2 = {
    width: 190,
    height: 350,
    playerVars: {
      autoplay: 0,
      mute: 1,
    },
  };
  const url =
    "https://www.youtube.com/watch?v=B_IYFFbKkfY&ab_channel=CodeWithYd";

  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    setAbout(data["abouts"]);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  const videoIds = ["aXL0zWJTLbM", "xyz123", "abc456"];
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <div className="res-bann">
            <BannerSection
              Text="من نحن"
              image="assets/images/res-about-contact.jpg"
            />
          </div>
          <div className="bann">
            <BannerSection Text="من نحن" image="assets/images/Artboard1.png" />
          </div>
          <div className="mt-10">
            <div className="aboutus-wrapper w-full">
              <div className="container-x mx-auto">
                <div className="w-full min-h-[665] lg:flex lg:space-x-12 items-center lg:pb-0">
                  <div className="resp-about md:w-[570px] w-full md:h-[450px] h-auto rounded overflow-hidden my-5 lg:my-0 ml-10">
                    <img
                      src="assets/images/Artboard2.png"
                      alt="about"
                      className="main-image"
                    />
                  </div>
                  <div className="md:w-[570px] w-full md:h-[450px] h-auto rounded overflow-hidden my-5 lg:my-0 ml-10">
                    <img
                      src="assets/images/about.jpeg"
                      alt="about"
                      className="main-image"
                    />
                  </div>
                  <div className="content flex-1">
                    <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                      تعرف علينا أكثر!
                    </h1>
                    <p
                      className="text-[15px] text-qgraytwo leading-7 mb-2.5"
                      style={{ fontFamily: "Light", color: "black" }}
                    >
                      {about.body_ar}
                    </p>
                  </div>
                </div>
                <div
                  className="mr-50 ml-90 mt-6"
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontFamily: "Light",
                    color: "#d9304f",
                    fontWeight: "900",
                  }}
                >
                  تم إعداد أكثر من 50 صنف لتحضير أشهى الفطائر{" "}
                </div>
                <div
                  className="mb-10"
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontFamily: "Light",
                    color: "#d9304f",
                    fontWeight: "900",
                  }}
                >
                  مع كاستيلو.
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="youtube-container">
                    <YouTube
                      videoId="aUj8l35YLnM"
                      opts={opts}
                      title="castello"
                      className="resp-video"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="youtube-videos"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "30",
                    }}
                  >
                    <div
                      className="youtube-videos1 m-7"
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <YouTube videoId="gnHQbg336RM" opts={opts2} />
                    </div>
                    <div
                      className="youtube-videos1  m-7"
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <YouTube videoId="tiIZgggPxdY" opts={opts2} />
                    </div>
                    <div
                      className="youtube-videos1  m-7"
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <YouTube videoId="rWSMWaKyjOE" opts={opts2} />
                    </div>
                    <div
                      className="youtube-videos1  m-7"
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <YouTube videoId="-A_jxAgxukg" opts={opts2} />
                    </div>
                    <div
                      className="youtube-videos1  m-7"
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <YouTube videoId="8PBhH64LWHQ" opts={opts2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
