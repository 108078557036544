/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Partials/Layout";
import BannerSection from "../../BannerSection/BannerSection";
import ProductCardStyleOne from "../ProductCardStyleOne";
import DataIteration from "../../../Helpers/DataIteration";
import AllProductPage from "../AllProductPage";
import ModalImage from "react-modal-image";

export default function ProductView2({ className }) {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchItem = async () => {
    const url = `https://castello.ps/fadi_shawer_backend/api/single_product/${params.id}`;

    const response = await fetch(url);
    const item = await response.json();
    setProduct(item["product"]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchItem();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <div className="res-bann">
            <BannerSection image="../../assets/images/res-ba1.jpg" />
          </div>
          <div className="bann">
            <BannerSection image="../../assets/images/pic3.jpeg" />
          </div>
          <div
            className={`product-view w-full mt-10 lg:flex justify-between mx-auto" ${
              className || ""
            }`}
            // style={{ width: "1200px" }}
          >
            <div
              // data-aos="fade-right"
              className="lg:w-1/3 xl:mr-[100px] lg:mr-[50px]"
            >
              <div className="w-full">
                <div
                  className="w-full h-[400px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="single-pic-product">
                    <ModalImage small={product.image} large={product.image} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div
                className="product-details w-full mt-10 lg:mt-0"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <p
                  data-aos="fade-up"
                  className="text-xl font-medium text-qblack mb-4"
                  style={{ fontFamily: "Light", fontSize: "50px" }}
                >
                  {product.name}
                </p>

                <p
                  data-aos="fade-up"
                  className="text-qgray text-sm text-normal mb-[30px] leading-7 mt-10"
                  style={{ fontFamily: "Light", width: "70%" }}
                >
                  {product.description}
                </p>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
