import { useEffect, useState } from "react";
import Layout from "../../Partials/Layout";
import BannerSection from "../BannerSection/BannerSection";

import Categories from "./Categories";

export default function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCategories(data["categories"]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <BannerSection
            image="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
            Text=""
          />

          <div
            className="categories-section-wrapper w-full"
            style={{ paddingTop: "50px" }}
          >
            <div className="container-x mx-auto" style={{width:'1100'}}>
              <div className="w-full categories-iems">
                <div
                  className="grid xl:grid-cols-3 sm:grid-cols-4 grid-cols-2 gap-5 mb-[20px]"
                  style={{ borderRadius: "10px" }}
                >
                  {categories.map((i) => (
                    <Categories name={i.name} image={i.image} id={i.id} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
