// eslint-disable-next-line import/no-unresolved
import { useEffect } from "react";
import { useState } from "react";
import "./SocialMediaButtons.scss";

export default function SocialMediaButtons() {
  const [socailMedia, setSocialMedia] = useState("");
  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setSocialMedia(data["socials"]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="social-container">
      <ul className="social-icons">
        <li>
          <a href={socailMedia.facebook}>
            <i className="fa fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href={socailMedia.instagram}>
            <i className="fa fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa fa-whatsapp"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}
