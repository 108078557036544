import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import CategoriesPage from "./components/Home/Category/CategoriesPage";
import AllProductPage from "./components/Home/Products/AllProductPage/index";
import ProductView from "./components/Home/Products/SingleProductPage/ProductView";
import ProductView2 from "./components/Home/Products/SingleProductPage/ProductView2";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/all-products" element={<AllProductPage />} />
      <Route exact path="/single-product/:id" element={<ProductView />} />
      <Route exact path="/single-product2/:id" element={<ProductView2 />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/category" element={<CategoriesPage />} />
    </Routes>
  );
}
