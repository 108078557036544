import GoogleMapReact from "google-map-react";
import { EnvelopeSimple, FacebookLogo, MapPin, Phone } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputCom from "../Helpers/InputCom";
import "./contact.css";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import { Container } from "react-bootstrap";
import { refresh } from "aos";

export default function ContactUs() {
  const [social, setSocial] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState(0);
  const [loading, setLoading] = useState(false);

  const [t] = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });
  const center = useMemo(() => ({ lat: 31.53898, lng: 35.062754 }), []);

  const fetchData = async () => {
    const response = await fetch(
      "https://castello.ps/fadi_shawer_backend/api/home_page"
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setSocial(data["socials"]);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  const handelSubmit = (e) => {
    e.preventDefault();
    const newContact = {
      name: e.target.first_name.value,
      email: e.target.email.value,
      message: e.target.message.value,
      phone: e.target.phone.value,
    };
    fetch("https://castello.ps/fadi_shawer_backend/api/add_message", {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
      },
      body: JSON.stringify(newContact),
    }).then((response) => response.json);
  };
  return (
    <>
      <div className="contact-page" style={{ backgroundColor: "#e5f5fc" }}>
        <div className="mx-auto " style={{ width: "1050px" }}>
          <div className="row">
            <div className="phone-number">
              <div className="phone-item">
                <Phone size={42} />
                <h1 className="phone-header">رقم الهاتف</h1>
                <p>{social.phone1}</p>
                <p>{social.phone2}</p>
              </div>
            </div>
            <div className="phone-number">
              <div className="phone-item">
                <FacebookLogo size={42} />
                <h1 className="phone-header">
                  تابعنا على مواقع التواصل الاجتماعي
                </h1>

                <a href={social.facebook}>
                  <span className="fa fa-facebook"></span>
                  <span style={{ margin: "5px" }}></span>
                </a>
                <a href={social.instagram}>
                  <span className="fa fa-instagram"></span>
                  <span style={{ margin: "5px" }}></span>
                </a>
              </div>
            </div>
            <div className="email">
              <div className="email-item">
                <EnvelopeSimple size={42} />
                <h1 className="email-header">الإيميل</h1>
                <p>{social.email}</p>
              </div>
            </div>
            <div className="address">
              <div className="address-item">
                <MapPin size={42} />
                <h1 className="address-header">العنوان</h1>
                <p>{social.address}</p>
              </div>
            </div>
          </div>
          <div
            className="map-form mt-5 mx-auto"
            // style={{ width: "1050px", height: "70vh" }}
          >
            <form
              className="form-contact"
              method="POST"
              // action="https://castello.ps/fadi_shawer_backend/api/add_message"
              onSubmit={handelSubmit}
            >
              <div className="title flex flex-col items-center ">
                <h1 className="text-[34px] font-bold text-qblack">
                  {t("Get In Touch")}
                </h1>
                <span className="-mt-5 block">
                  <svg
                    width="354"
                    height="20"
                    viewBox="0 0 354 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                      stroke="#FFBB38"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </div>
              <div className="inputs mt-5">
                <div className="mb-1">
                  <InputCom
                    label={t("First Name")}
                    name="first_name"
                    inputClasses="h-[50px]"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-1">
                  <InputCom
                    label={t("Email Address")}
                    name="email"
                    inputClasses="h-[50px]"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-1">
                  <InputCom
                    label={t("phone")}
                    name="phone"
                    inputClasses="h-[50px]"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="mb-1">
                  <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                    {t("Message")}
                  </h6>
                  <textarea
                    className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div>
                  <div className="black-btn text-sm font-semibold w-full h-[50px] flex justify-center items-center">
                    <button type="submit">{t("Send Now")}</button>
                  </div>
                </div>
              </div>
            </form>
            <Container fluid>
              <div className="map">
                {!isLoaded ? (
                  <></>
                ) : (
                  <div style={{ width: "650px" }}>
                    <GoogleMap
                      center={center}
                      zoom={10}
                      mapContainerStyle={{ height: "500px", width: "100%" }}
                    >
                      <Marker position={{ lat: 31.53898, lng: 35.062754 }} />
                    </GoogleMap>
                  </div>
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
